
import { defineComponent, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getStreamsDetail } from "./streams";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import moment from "moment";
import { ElMessageBox } from "element-plus";
import { updateStreams } from "./streams";
import Swal from "sweetalert2/dist/sweetalert2.js";

interface MediaCetakForm {
  title: string;
  source: string;
  account: string;
  program: string;
  content: string;
  date: string;
  emotion: string;
  file_source: string;
  journalist: string;
  page: string;
  rate_bw: string;
  rate_fc: string;
  sentiment: string;
  is_news_photo: boolean;
  duration: string;
  ad_value: number;
  jenis_data: string;
}

interface filesUploader {
  name: string;
  url: string;
}

export default defineComponent({
  name: "detail-berita",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();
    const route = useRoute();
    const berita = ref();
    const id = ref(route.params.id);
    const loading = ref(false);
    const media_options = ref([]);
    const file_list = ref([]);
    const files = ref<Array<filesUploader>>([]);
    const valueContent = ref("");
    const keyRateFc = ref(0);
    const keyRateBw = ref(0);
    const valueMedia = ref("");
    const valueDate = ref("");
    const keyContent = ref(0);
    const file_upload_header = {
      Authorization: `Bearer ${JwtService.getToken()}`,
    };
    const actionURL = `${process.env.VUE_APP_API_URL}/streams/uploadfile`;
    const MediaCetakFormValidator = Yup.object().shape({
      title: Yup.string().required().label("Judul Berita"),
      source: Yup.string().required().label("Sumber Berita"),
      is_news_photo: Yup.string().when("source", {
        is: () =>
          MediaCetakForm.value.source === "media_cetak" &&
          MediaCetakForm.value.is_news_photo == null,
        then: Yup.string().required(
          "Keterangan berita bergambar is a required field"
        ),
        otherwise: Yup.string().notRequired(),
      }),
      jenis_data: Yup.string().when("source", {
        is: () =>
          MediaCetakForm.value.source === "media_tv" &&
          MediaCetakForm.value.jenis_data == "",
        then: Yup.string().required("Jenis data is a required field"),
        otherwise: Yup.string().notRequired(),
      }),
      account: Yup.string().required().label("Nama Media"),
      // program: Yup.string().when("source", {
      //   is: (val) => val === "media_tv",
      //   then: Yup.string().required("Program"),
      //   otherwise: Yup.string().notRequired(),
      // }),
      content: Yup.string().required().label("Content"),
      date: Yup.string().required().label("Tanggal Berita"),
      emotion: Yup.string().label("Emotion"),
      file_source: Yup.string().required().label("File Source"),
      // journalist: Yup.string().required().label("Journalist"),
      sentiment: Yup.string().label("Sentimen"),
      ad_value: Yup.string().when("source", {
        is: () => MediaCetakForm.value.source === "media_tv",
        then: Yup.string()
          .required("AD Value is a required field")
          .test("is-not-zero", "Nilai tidak boleh 0", (value) => value !== "0"),
        otherwise: Yup.string().notRequired(),
      }),
      duration: Yup.string().when("source", {
        is: () => MediaCetakForm.value.source === "media_tv",
        then: Yup.string()
          .required("Duration is a required field")
          .test("is-not-zero", "Nilai tidak boleh 0", (value) => value !== "0"),
        otherwise: Yup.string().notRequired(),
      }),
    });

    const MediaCetakForm = ref<MediaCetakForm>({
      title: "",
      source: "",
      account: "",
      program: "",
      content: "",
      date: "",
      emotion: "",
      file_source: "",
      journalist: "",
      page: "",
      rate_bw: "",
      rate_fc: "",
      sentiment: "",
      is_news_photo: true,
      ad_value: 0,
      duration: "",
      jenis_data: "",
    });

    // Media TV
    const rate_perdetik = ref(0);
    const ppn = ref(0);

    const fetchStreamDetail = async () => {
      const res = await getStreamsDetail(id.value);
      berita.value = res;
      MediaCetakForm.value.title = berita?.value.title;
      MediaCetakForm.value.source = berita?.value.source;
      MediaCetakForm.value.account = berita?.value.account;
      MediaCetakForm.value.program = berita?.value.program;
      MediaCetakForm.value.content = berita?.value.content;
      MediaCetakForm.value.rate_bw = thousandSeparator(berita?.value.rate_bw);
      MediaCetakForm.value.rate_fc = thousandSeparator(berita?.value.rate_fc);
      MediaCetakForm.value.page = berita?.value.page;
      MediaCetakForm.value.sentiment = berita?.value.sentiment.toString();
      MediaCetakForm.value.emotion = berita?.value.emotion.toString();
      MediaCetakForm.value.journalist = berita?.value.journalist;
      MediaCetakForm.value.date = berita?.value.date;
      MediaCetakForm.value.source = berita?.value.source;
      MediaCetakForm.value.is_news_photo = berita?.value.is_news_photo;
      MediaCetakForm.value.jenis_data = berita?.value.jenis_data;
      MediaCetakForm.value.ad_value = berita?.value.ad_value || 0;
      MediaCetakForm.value.duration = berita?.value.duration || 0;
      if (berita?.value.source != "news") {
        for (let index = 0; index < berita?.value.file_source.length; index++) {
          let name = berita?.value.file_source[index].split("/");
          let data = {
            name: name[4],
            url: berita?.value.file_source[index],
          };
          files.value.push(data);
        }
        MediaCetakForm.value.file_source = berita?.value.file_source[0];
      } else {
        MediaCetakForm.value.file_source = berita?.value.file_source;
      }

      if (berita?.value.source == "media_tv") {
        checkADValueFromDuration();
      }

      valueContent.value = berita?.value.content;
      keyContent.value += 1;
    };
    const checkADValueFromDuration = () => {
      rate_perdetik.value = 0;
      ppn.value = 0;

      if (
        MediaCetakForm.value.account &&
        MediaCetakForm.value.date &&
        MediaCetakForm.value.duration
      ) {
        ApiService.post("/streams/rumus-ad-value", {
          data: {
            nama_media: MediaCetakForm.value.account,
            publish_date: MediaCetakForm.value.date,
            durasi_video: Number(MediaCetakForm.value.duration.toString().replace(/[.]/g, "")),
          },
        })
          .then((res) => {
            MediaCetakForm.value.ad_value = thousandSeparator(res.data.ad_value) || "0";
            rate_perdetik.value = thousandSeparator(res.data.rate_per_detik) || "0";
            ppn.value = res.data.ppn || 0;
          })
          .catch(() => {
            MediaCetakForm.value.ad_value = 0;
          });
      }
    };
    const saveChanges1 = async () => {
      if (submitButton1.value) {
        let loadout = MediaCetakForm.value;
        loadout.ad_value = Number(loadout.ad_value.toString().replace(/[.]/g, ""));
        loadout.rate_bw = loadout.rate_bw.toString().replace(/[.]/g, "");
        loadout.rate_fc = loadout.rate_fc.toString().replace(/[.]/g, "");
        if (loadout.source != "news") {
          let file_source: any[] = files.value;
          loadout.file_source = file_source.map((el: any) => el.url).join(",");
        }

        if (loadout.source == "media_tv") {
          loadout.duration = loadout.duration.toString().replace(/[.]/g, "");
        }
        
        // JANGAN DULU DIHAPUS
        // if (loadout.source != "news") {
        //   if (file_list.value.length > 0) {
        //     loadout.file_source +=
        //       "," +
        //       formatFileSource([file_list.value[file_list.value.length - 1]]);
        //   }
        // }

        loadout.date = moment(loadout.date).format("YYYY-MM-DD HH:mm:ss");
        
        if (loadout.source == 'media_cetak' && (loadout.rate_bw == '0' && loadout.rate_fc == '0')) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            toast: true,
            title: "Mohon Periksa Kolom Ad Value",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          submitButton1.value.setAttribute("data-kt-indicator", "on");
  
          await updateStreams(loadout, id.value)
            .then((response) => {
              if (response.status == 200) {
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  toast: true,
                  title: "Berhasil! Perubahan berita berhasil disimpan",
                  showConfirmButton: false,
                  timer: 3000,
                });
              } else {
                Swal.fire({
                  position: "top-end",
                  icon: "error",
                  toast: true,
                  title: "Gagal! Perubahan berita gagal disimpan",
                  showConfirmButton: false,
                  timer: 3000,
                });
              }
            })
            .catch((error) => {
              Swal.fire({
                position: "top-end",
                icon: "error",
                toast: true,
                title: "Gagal! Perubahan berita gagal disimpan",
                showConfirmButton: false,
                timer: 3000,
              });
            })
            .finally(() => {
              // Deactivate indicator
              submitButton1.value?.removeAttribute("data-kt-indicator");
            });
        }
      }
    };

    const formatFileSource = (file_list) => {
      let file_source = "";
      for (let i = 0; i < file_list.length; i++) {
        if (i == 0) {
          if ("response" in file_list[i]) {
            file_source = file_list[i].response.file;
          } else {
            file_source = file_list[i].url;
          }
        } else {
          if ("response" in file_list[i]) {
            file_source = file_source + "," + file_list[i].response.file;
          } else {
            file_source = file_source + "," + file_list[i].url;
          }
        }
      }
      return file_source;
    };

    const isNumber = (evt) => {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        (charCode !== 46 || charCode !== 190)
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    };

    const onPaste = (evt) => {
      let value = evt.clipboardData.getData("text");
      if (value.match(/^[0-9]+$/) != null) {
        if (evt.currentTarget.id == "rateBw") {
          MediaCetakForm.value.rate_bw = value;
          keyRateBw.value += 1;
        } else {
          MediaCetakForm.value.rate_fc = value;
          keyRateFc.value += 1;
        }
      } else {
        if (evt.currentTarget.id == "rateBw") {
          MediaCetakForm.value.rate_bw = "";
          keyRateBw.value += 1;
        } else {
          MediaCetakForm.value.rate_fc = "";
          keyRateFc.value += 1;
        }
      }
    };

    const setFileUpload = (response, file, fileList) => {
      file_list.value = fileList;
      const file_temp = {
        name: fileList[fileList.length - 1].name,
        url: fileList[fileList.length - 1].response.file,
      };
      files.value.push(file_temp);
      if (fileList.length > 0) {
        MediaCetakForm.value.file_source = response.file;
      }
    };

    const removeFileUpload = (file, fileList) => {
      let file_to_remove = "";
      if ("response" in file) {
        file_to_remove = file.response.file;
      } else {
        file_to_remove = file.url;
      }
      ApiService.post("/streams/removefile", { data: { path: file_to_remove } })
        .then((response) => {
          files.value = files.value.filter((el: any) => el.name !== file.name);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const errorFileUpload = (error, file, fileList) => {
      let message = JSON.parse(error.message);
      ElMessageBox.alert("Silakan periksa inputan Anda.", message.detail, {
        confirmButtonText: "OK",
        type: "warning",
        center: true,
      });
    };

    const remoteMethod = (query) => {
      if (query.length > 2) {
        loading.value = true;
        let url = "/media?media_like=" + query;
        if (MediaCetakForm.value["source"] != "") {
          url = url + "&source=" + MediaCetakForm.value["source"];
        }
        ApiService.get(url)
          .then((response) => {
            media_options.value = response.data;
            return media_options.value;
          })
          .catch((error) => {
            console.log(error);
            loading.value = false;
          })
          .finally(() => {
            loading.value = false;
          });
        return [];
      } else {
        media_options.value = [];
      }
    };

    const cekFileSource = (file, fileList) => {
      if (fileList.length == 0) {
        MediaCetakForm.value["file_source"] = "";
      }
    };

    const convertText = (valueContent) => {
      if (MediaCetakForm.value["content"] != null) {
        let text = MediaCetakForm.value["content"].split("\n\n");
        let newText = "";

        for (let i = 0; i < text.length; i++) {
          let perTextArray = text[i].split("\n");
          for (let j = 0; j < perTextArray.length; j++) {
            if (perTextArray[j].charAt(perTextArray[j].length - 1) == "-") {
              newText += perTextArray[j].replace(/-/g, "");
            } else {
              newText += perTextArray[j] + " ";
            }
          }
          newText += "\n";
        }
        MediaCetakForm.value.content = newText;
        valueContent = newText;
      }
    };

    const thousandSeparator = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Edit Berita", ["Berita"]);
      fetchStreamDetail();
    });

    return {
      submitButton1,
      checkADValueFromDuration,
      saveChanges1,
      MediaCetakForm,
      MediaCetakFormValidator,
      berita,
      setFileUpload,
      loading,
      remoteMethod,
      media_options,
      removeFileUpload,
      file_list,
      cekFileSource,
      file_upload_header,
      actionURL,
      errorFileUpload,
      convertText,
      valueContent,
      isNumber,
      onPaste,
      keyRateFc,
      keyRateBw,
      valueDate,
      valueMedia,
      keyContent,
      files,
      rate_perdetik,
      ppn,
    };
  },
  methods: {
    thousandSeparator(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    handleInputBW() {
      this.MediaCetakForm.rate_bw = this.MediaCetakForm.rate_bw.replace(
        /[.]/g,
        ""
      );
    },
    handleInputFC() {
      this.MediaCetakForm.rate_fc = this.MediaCetakForm.rate_fc.replace(
        /[.]/g,
        ""
      );
    },
    handleChangeBW(event) {
      this.MediaCetakForm.rate_bw = this.thousandSeparator(event.target.value);
    },
    handleChangeFC(event) {
      this.MediaCetakForm.rate_fc = this.thousandSeparator(event.target.value);
    },
    handleInputDurasiVideo() {
      this.MediaCetakForm.duration = this.MediaCetakForm.duration.toString().replace(/[.]/g, "");
    },
    handleChangeDurasiVideo(event) {
      this.MediaCetakForm.duration = this.thousandSeparator(event.target.value);
    },
  },
  computed: {
    acceptFile() {
      let str_accept_file = "image/png, image/jpeg, image/jpg";
      if (this.MediaCetakForm["source"] == "media_tv") {
        str_accept_file = "video/mp4,video/x-m4v";
      }
      return str_accept_file;
    },
  },
});
